//
// Excel page.scss
//

.tab-pane {
  .ant-table {
    // opacity: 0.2;
    overflow-x: scroll;
  }
}
