//
// _badge.scss
//

.badge {
  &[href] {
    &:hover,
    &:focus {
      color: $white;
    }
  }
}

// Soft badge
@mixin badge-variant-soft($bg) {
  color: $bg;
  background-color: rgba($bg, 0.18) !important;

  &[href] {
    &:hover,
    &:focus {
      color: $bg;
      text-decoration: none;
      background-color: rgba($bg, 0.4);
    }
  }
}

@each $color, $value in $theme-colors {
  .badge {
    &.bg-#{$color} {
      &[href] {
        &:hover,
        &:focus {
          background-color: darken($value, 4%) !important;
        }
      }
    }
  }

  .badge {
    &.bg-light {
      color: $body-color;

      &[href] {
        &:hover,
        &:focus {
          color: $body-color;
        }
      }
    }
  }

  .badge-soft-#{$color} {
    @include badge-variant-soft($value);
  }
}

.rounded-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
}

// Dark badge

.badge.bg-dark {
  color: $light;
}

.badge {
  &.bg-project-status- {
    &0 {
      background-color: $secondary;
    }
    &1 {
      background-color: $info;
    }
    &2 {
      background-color: $primary;
    }
    &3 {
      background-color: $success;
    }
  }
}
